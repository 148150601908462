.order-summary {
  margin: 20px;
}

.order-summary .total {
  color: #00a6a3;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 25px;
  font-weight: bold;
  margin-top: 30px;
}

.order-summary .billing-cycle {
  margin-top: -5px;
  margin-bottom: 10px;
  color: #3a3a3a;
  font-size: 12px;
}

.bottomImage {
  margin-top: 40px;
}
