.kbContent {
  font-size: 15px;
}

.kbContent img {
  max-width: 80%;
  height: auto;
}

.kbContent > div > div {
  margin-bottom: 10px;
}
